import { UrlParamsReplace } from './index';

export const ADMIN_LOGIN_URL = () => UrlParamsReplace('/v2/admin-session');

export const REFRESH_SESSION = () => UrlParamsReplace('/refresh-sessions');

export const SEND_CONTEXT_OTP_URL = () => UrlParamsReplace('/otp/send');

export const VERIFY_CONTEXT_OTP_URL = () => UrlParamsReplace('/otp/verify');

export const LOGIN_USER_URL = () => UrlParamsReplace('/v2/user-sessions');
