import { UrlParamsReplace } from './index';

export const GET_AI_SAFE_TRANSACTIONS = (investorId) =>
    UrlParamsReplace(
        `/wallet-transactions?investorId=:investorId`,
        {
            investorId
        }
    );
export const GET_WALLET = (investorId) =>
    UrlParamsReplace(`/wallet?investorId=:investorId`, {
        investorId,
    });

export const GET_ALL_WALLET_TRANSACTIONS_DATA_FILE_NAME = (investorId) =>
    UrlParamsReplace(`/wallet-transactions/report?investorId=:investorId`, {
        investorId,
    });

export const CREATE_WITHDRAWAL_REQUEST = () =>
    UrlParamsReplace(`/withdrawal-requests`);

export const GET_IN_PROGRESS_WITHDRAWAL_REQUESTS = (investorId) =>
    UrlParamsReplace(`/withdrawal-requests/investors/:investorId/inProgress`, {
        investorId,
    });
export const GET_PENDING_WITHDRAWAL_REQUESTS = (page, limit) =>
    UrlParamsReplace(`/withdrawal-requests/pending?limit=:limit&page=:page`, {
        limit,
        page,
    });
export const GET_WITHDRAWAL_REQUESTS = (page, limit) =>
    UrlParamsReplace(
        `/withdrawal-requests/inProgress?limit=:limit&page=:page`,
        {
            limit,
            page,
        }
    );
export const INITIATE_WITHDRAWAL_REQUEST = (id) =>
    UrlParamsReplace(`/withdrawal-requests/:id/initiate`, {
        id,
    });
